@import "../../../node_modules/bootstrap/scss/bootstrap";


$primary: #4158D0;

$theme-colors: (
  "primary": $primary
);


body {
  font-family: 'DM Sans', sans-serif;
  background-color: #F2F3F6;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: calc(100% - 40px);

}

.app-form-control {
  width: 100%;
  border-radius: .5;
}

.bg-gradient-primary {
  background-color: $primary;
  background-image: linear-gradient(180deg,$primary 10%,$primary 100%);
  background-size: cover;
}

.text-primary {
  color: $primary !important;
}

.border-left-primary {
  border-left: .25rem solid $primary !important;
}

.app-input {
  input {
    height: 45px;
    background-color: #FAFAFB;
    
    &::-webkit-input-placeholder,
    &::placeholder {
      color: #92929Daa;
    }
  }
}

.d-block-1 {
  height: 25px;
  border-radius: 45px;
  margin: 10px 20px 115px;
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 3px;
  font-weight: 600;
  color: #524f4e;
  box-shadow: 0 8px 15px rgba(0, 0, 0, .1);
  transition: .3s;
}

.d-block-1:hover {
  color: black;
  transform: translateY(-7px);
}

.copy-icon {
  height: 20px;
  margin: 10px 20px 115px;
  cursor: pointer;
  color: #494949;
}

.copy-icon:hover {
  color: #000000;
}
