#root {
  /*font-family: 'Arial', sans-serif;*/
  /*background-color: #FDD835; !* Adjusted to match the yellow color in the image *!*/
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: "wf_2";
}

.header {
  height: 90px;
  display: flex;
  /* width: 100%; */
  justify-content: space-around;
  align-items: center;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card {
  width: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  /* padding: 20px; */
  text-align: center;
  /*border-color: #A238FF;*/
  /*border: 1px solid;*/
}

.card-header {
  background: black;
  border-radius: 8px 8px 0px 0px;
  padding: 20px;
}

.card-content {
  background-color: #FDD835;
  border-radius: 0px 0px 8px 8px;
  padding: 20px;
}

.card h2 {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 20px;
  margin-bottom: 8px;
}

.team-name {
  color: white;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0px;
}

.instructions {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.code {
  background-color: #a4720f;
  color: #ffffff;
  padding: 12px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 20px;
  font-size: 50px;
  cursor: pointer;
  width: 250px;
  height: 85px;
}

#code {
  font-size: 40px;
}

.copy-button {
  position: relative;
  margin-top: -24px;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid #a4720f;
  border-radius: 20px;
  width: 205px;
  background-color: white;
  color: black;
  left: 12px;
  height: 33px;
}

.app-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-left: -16px;
}

.app-button {
  color: #ffffff;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  width: 127px;
  height: 41px;
}

.footer {
  text-align: center;
  padding: 20px 0 15px 0;
  flex-shrink: 0;
  font-family: wf_d2b74fa2704044fa98373433c;
  font-size: 14px;
  color: #585858;
}

.footer p {
  font-size: 14px;
  text-align: center;
  color: #585858;
  font-family: wfont_3f090f_d2b74fa2704044fa98373433c883f8ed, wf_d2b74fa2704044fa98373433c, orig_poppins_regular;
}

.card-header.error-message {
  color: white;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 0px;
}

.card-content.error-message {
  color: #000;
  font-size: 30px;
  margin-bottom: 0px;
  min-height: 100px;
}

@font-face {
  font-display: block;
  font-family: wf_2;
  src: url("https://static.wixstatic.com/ufonts/3f090f_d2b74fa2704044fa98373433c883f8ed/woff2/file.woff2") format("woff2"), url("https://static.wixstatic.com/ufonts/3f090f_d2b74fa2704044fa98373433c883f8ed/woff/file.woff") format("woff"), url("https://static.wixstatic.com/ufonts/3f090f_d2b74fa2704044fa98373433c883f8ed/ttf/file.ttf") format("ttf");
}

